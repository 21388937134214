<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()" v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      id="print"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه متابعة العملاء المستهدفين
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column
        field="code"
        header="الكود"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث بالكود"
          />
        </template>
      </Column>
      <Column
        field="date"
        header="تاريخ المهمه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ المهمه"
          />
        </template>
      </Column>
      <Column field="usersId.name" header="مسئول المبيعات" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            v-model="filters['usersId.name']"
            placeholder="بحث بمسئول المبيعات"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of usersList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>
      <Column field="citiesId.name" header="المدينه" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="citiesId"
            name="citiesId"
            v-model="filters['citiesId.name']"
            placeholder="بحث بالمدينه"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of citiesList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>
      <Column
        field="clientName"
        header="العميل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['clientName']"
            class="p-column-filter"
            placeholder="بحث باسم العميل"
          />
        </template>
      </Column>
      <Column
        field="who"
        header="النشاط"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['who']"
            class="p-column-filter"
            placeholder="بحث باسم النشاط"
          />
        </template>
      </Column>

      <Column field="status" header="الحاله" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="status"
            name="status"
            v-model="filters['status']"
            placeholder="بحث بالحاله"
          >
            <option :value="null">الكل </option>

            <option value="1">غير مهتم</option>
            <option value="2">اهتمام بسيط</option>
            <option value="3">مهتم</option>
            <option value="4">مهتم جدا</option>
          </select>
        </template>
        <template #body="slotProps">
          {{ slotProps.data.status == 1 ? 'غير مهتم' : '' }}
          {{ slotProps.data.status == 2 ? 'اهتمام بسيط' : '' }}
          {{ slotProps.data.status == 3 ? 'مهتم' : '' }}
          {{ slotProps.data.status == 4 ? 'مهتم جدا' : '' }}
        </template>
      </Column>
      <Column
        field="managerName"
        header="المسئول"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['managerName']"
            class="p-column-filter"
            placeholder="بحث باسم المسئول"
          />
        </template>
      </Column>
      <Column
        field="managerPhone"
        header="جول المسئول"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['managerPhone']"
            class="p-column-filter"
            placeholder="بحث بجول المسئول"
          />
        </template>
      </Column>
      <Column
        field="phone"
        header="هاتف"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['phone']"
            class="p-column-filter"
            placeholder="بحث بالهاتف"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      citiesList: [],
      usersList: [],
      listx: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    getData() {
      if (this.$checkRoles('tasksAll')) {
        this.$http.get(`tasks`).then(
          (response) => {
            this.loading = false;
            this.list = response.data;
            this.listx = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        const user = JSON.parse(localStorage.admin);
        this.$http.get(`tasks?usersId=` + user.id).then(
          (response) => {
            this.loading = false;
            this.list = response.data;
            this.listx = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      }
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`tasks/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`tasks/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.$http.get(`cities`).then((res) => {
      this.citiesList = res.data;
    });
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.col-form-label {
  text-align: start;
}
</style>
